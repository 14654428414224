<template>
  <div class="col text-left">
    <h3>Mail-Variablen</h3>
    <p>Variablen können überall im Mailing plaziert werden. Sie werden beim Versand des Mailings durch personenbezogene Daten ersetzt.</p>
    <br>
    <h4>Personen Variablen</h4>
    <p><code>%salutation[<var>lang</var>]%</code> für die Anrede (Herr/Frau) (Mr/Mrs) (Monsieur/Madame)<br><small> lang:[de/en/fr/es]</small></p>
    <p><code>%firstname%</code> für den Vorname</p>
    <p><code>%lastname%</code> für den Nachname</p>
    <p><code>%company%</code> für die Firma</p>
    <p><code>%customernumber%</code> für die Kundennummer</p>
    <p><code>%email%</code> für die E-Mail-Adresse</p>
    <br>
    
    <h4>Preis Variablen</h4>
    <p><code>%custom_price[<var>cl-artikelnummer</var>]%</code> gibt den Preis anhand der Kundennummer aus. (85,10 / 85.10)</p>
    <p><code>%customer_currency%</code> gibt die Währung anhand der Kundennummer aus. (€ / £)</p>
    <p><code>%custom_price_currency[<var>cl-artikelnummer</var>]%</code> gibt den Preis und die Währung anhand der Kundennummer aus. (85,10 € / £ 85.10)</p>
    <br>

    <h4>Allgemeine Variablen</h4>
    <p><code>%webversion%</code> gibt die URL zur Webversion aus.</p>
    <p><code>%preference-center%</code> gibt die URL zum Preference Center aus, hier kann der Kunde seine Daten bearbeiten und und von den Newslettern abmelden.</p>
    <p>Beispiel: &lt;a name="Abmeldung" href="<b>%preference-center%</b>"&gt; Hier abmelden oder E-Mail-Adresse ändern &lt;/a&gt; </p>
  </div>
</template>

<script>
export default {
  name: 'dokuVariablen'
}
</script>

<style scoped>
  var {
    color: #4089c9;
  }
  code {
    border: 1px #ced4da solid;
    border-radius: .25rem;
    padding: 5px;
  }
  </style>
